/**
 * Functions in utils
 */
import moment from "moment";
import numeral from "numeral";

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x) => {
	if (x) {
		// if (x > 1000) {
		// 	return x.toLocaleString('en-US', { minimumFractionDigits: decimal });
		// } else {
		// 	return x
		// }
		return numeral(x).format('0,0')

	} else {
		return x
	}
};

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
	const extension = filename.split('.').pop();
	return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
	let color = '';
	switch (itemstatus) {
		case 'In Progress':
			color = 'info';
			break;
		case 'Pending':
			color = 'warning';
			break;
		case 'Finished':
			color = 'success';
			break;
		case 'Cancel':
			color = 'danger';
			break;
		default:
			color = 'primary';
	}
	return color;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
	let color = '';
	switch (category) {
		case 'Saas Services':
		case 'Entertainment':
		case 'Extra':
			color = 'info';
			break;
		case 'Design':
			color = 'warning';
			break;
		case 'Marketing':
			color = 'success';
			break;
		case 'Development':
			color = 'danger';
			break;
		case 'SEO':
			color = 'primary';
			break;
		default:
			color = 'primary';
	}
	return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
	const tmp = [...arr];
	if (chunkSize <= 0) return cache;
	while (tmp.length) cache.push(tmp.splice(0, chunkSize));
	return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

export const getTotalRating = (reviews) => {
	let _total = 0;
	reviews?.forEach((item) => {
		if (item.rating) {
			_total += parseFloat(item.rating);
		}
	});

	return (_total / reviews?.length).toFixed(1);
};

// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
	const month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	const yyyy = date.getFullYear();
	let mm = date.getMonth();
	let dd = date.getDate();
	var today = month[mm] + ' ' + dd + ', ' + yyyy;
	return today;
};

// function to generate slug or ID with slug format
export const getSlug = (text) => {
	text = text.toLowerCase();
	text = text.replace(/ /g, '-').replace(/[^\w-]+/g, '');
	return text;
};

// function to apply currency symbol with two digits decimal values
export const convertToCurrency = (value) =>
	'$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');


export const COURSE_COVERS = [
	'https://source.unsplash.com/dNe6TyX_laM',
	'https://source.unsplash.com/YgONpSF3Q28',
	'https://source.unsplash.com/3AsiVDsZnHg',
	'https://source.unsplash.com/v47ltneXusM',
	'https://source.unsplash.com/1l5JpOVXSGo',
	'https://source.unsplash.com/_W94Eb1iNYc',
	'https://source.unsplash.com/HYrg5PG9d0E',
]

export const formatDate = (date) => {
	if (date) {
		return moment(date).format('DD MMM yyyy')
	} else {
		return date;
	}
}

export const getDateTime = (date) => {
	if (date) {
		const now = moment();
		const inputDate = moment(date);

		if (now.isSame(inputDate, 'day')) {
			// Return time if today
			return inputDate.format('HH:mm');
		} else {
			// Return date and time otherwise
			return inputDate.format('DD MMM yyyy HH:mm');
		}
	} else {
		return date;
	}
}

export const checkQuizAvailability = (quiz) => {
	const today = moment();
	const startDate = moment(quiz?.startDateTime);
	const endDate = moment(quiz?.endDateTime);

	console.log('check start', today.isSameOrAfter(startDate));
	console.log('check end',  today.isSameOrBefore(endDate) );

	return today.isAfter(startDate) && today.isBefore(endDate);

}

export const truncateTextByCount = (text, count) =>{
	if(text && count){
		const words = text.split(' ');

		if (words.length <= count) {
			return text;
		}
	
		return words.slice(0, count).join(' ') + '...';
	} else {
		return text;
	}
}

export const validatePassword = (password) => {
	
	return password?.length >= 8
}

const utils = [
	numberWithCommas,
	getFileExtension,
	getRandomNo,
	getStatusColor,
	chunk,
	getTimeValue,
	getDateValue,
	getSlug,
	convertToCurrency,
	COURSE_COVERS
];

export default utils;
