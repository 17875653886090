// import node module libraries
import React, { Fragment, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { Col, Row, Container, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { MoreVertical } from "react-feather";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// import MDI icons
// import Icon from "@mdi/react";
// import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiContentCopy } from "@mdi/js";

// import custom components
// import GKYouTube from 'components/marketing/common/video/GKYouTube';
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";

// import data
// import { CourseIndex } from 'data/marketing/CourseIndexData';
import VideoPlayer from "components/marketing/common/video/VideoPlayer";
// import PDFViewerComponent from "components/marketing/common/pdf-viewer/PDFViewerComponent";

import { GET_COURSE_MODULES, GET_FILE_URL, GET_MODULE_BY_ID } from "helper/graphql/queries";
import GKAccordionModule from "components/marketing/common/accordions/GKAccordionModule";
import SurveyViewer from "components/elements/survey-viewer/SurveyViewer";
import { Notify } from "notiflix";
import { CREATE_QUIZ_ANSWER } from "helper/graphql/mutations";
import useAuth from "hooks/useAuth";
import { PATH_DASHBOARD } from "routes/Paths";
// import DocumentViewer from "components/marketing/common/pdf-viewer/DocumentViewer";
import PdfViewer from "components/marketing/common/pdf-viewer/PdfViewer";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//   <Link
//     to="#"
//     ref={ref}
//     onClick={(e) => {
//       e.preventDefault();
//       onClick(e);
//     }}
//   >
//     {children}
//   </Link>
// ));

// const ActionMenu = () => {
//   return (
//     <Dropdown>
//       <Dropdown.Toggle as={CustomToggle}>
//         <MoreVertical size="15px" className="text-secondary" />
//       </Dropdown.Toggle>
//       <Dropdown.Menu align="end">
//         <Dropdown.Header>SHARE</Dropdown.Header>
//         <Dropdown.Item eventKey="1">
//           <Icon path={mdiFacebook} size={0.8} className="text-secondary" />{" "}
//           Facebook
//         </Dropdown.Item>
//         <Dropdown.Item eventKey="2">
//           <Icon path={mdiTwitter} size={0.8} className="text-secondary" />{" "}
//           Twitter
//         </Dropdown.Item>
//         <Dropdown.Item eventKey="3">
//           <Icon path={mdiLinkedin} size={0.8} className="text-secondary" />{" "}
//           Linked In
//         </Dropdown.Item>
//         <Dropdown.Item eventKey="4">
//           <Icon path={mdiContentCopy} size={0.8} className="text-secondary" />
//           Copy Link
//         </Dropdown.Item>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

export const CourseResume = () => {
  const { guid } = useParams();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isReview = pathname.includes('review');

  const [contents, setContents] = useState([]);
  // const [resources, setResources] = useState([]);
  // const [quizes, setQuizes] = useState([]);

  const [module, setModule] = useState();
  // const [course, setCourse] = useState(null);
  const [allModules, setModules] = useState(null);

  const [currTopic, setCurrTopic] = useState(null);
  const [currTopicFile, setCurrTopicFile] = useState(null);

  const [getFile] = useLazyQuery(GET_FILE_URL)

  const [onCreateQuizAnswer] = useMutation(CREATE_QUIZ_ANSWER);

  useQuery(GET_MODULE_BY_ID, {
    variables: { guid: guid },
    onCompleted: (data) => {
      const _module = data?.allCourseModules?.edges[0]?.node;
      const _resources = _module?.coursemoduleresourcesSet?.edges?.map(
        (item) => item.node
      );

      const _quizes = _module?.modulecontentquizSet?.edges?.map(
        (item) => item.node
      );

      const _topics = _module?.coursemodulecontentsSet?.edges?.map(
        (item) => item.node
      );

      if (isReview) {
        // console.log('is_review', _topics, data)
      }

      // setResources(_resources);
      setModule(_module);
      setContents([
        {
          id: 101,
          title: "Topics",
          items: _topics,
          disabled: true,
          icon: 'book-open'
        },
        {
          id: 102,
          title: "Resources",
          items: _resources,
          disabled: false,
          icon: 'file-text'
        },
        {
          id: 103,
          title: "Quizzes",
          items: _quizes,
          disabled: false,
          icon: 'help-circle'
        },

      ]);

      const topic = _resources[0];

      setCurrTopic(topic);
      if (topic.contentType && topic.contentType === "file") {
        handleGetFileURL(topic?.file);
      } else {
        setCurrTopicFile(null)
      }
    },
  });

  useQuery(GET_COURSE_MODULES, {
    variables: { courseGuid: module?.course?.guid },
    onCompleted: (data) => {
      setModules(data?.allCourseModules?.edges?.map(mod => mod?.node));
    }
  });

  const getNextModule = (modules, currentGuid) => {
    if (modules?.length !== 0 && currentGuid) {
      const currIndex = modules?.findIndex(mod => mod.guid === currentGuid);
      if (currIndex >= 0 && (currIndex + 1) < modules?.length) {
        return modules[currIndex + 1]
      }
      return null
    } else {
      return null;
    }
  }

  // console.log('next', getNextModule(allModules, guid))

  const getPrevModule = (modules, currentGuid) => {
    if (modules?.length !== 0 && currentGuid) {
      const currIndex = modules?.findIndex(mod => mod.guid === currentGuid);
      if (currIndex > 0) {
        return modules[currIndex - 1]
      }
      return null
    } else {
      return null;
    }
  };

  const handleNextLecture = () => {
    const _next = getNextModule(allModules, guid);
    if (_next !== null) {
      navigate(PATH_DASHBOARD.courses.resume(_next.guid))
    }
  };

  const handlePreviousLecture = () => {
    const _next = getPrevModule(allModules, guid);
    if (_next !== null) {
      navigate(PATH_DASHBOARD.courses.resume(_next.guid))
    }
  }

  const handleGetFileURL = (filename) => {
    getFile({
      variables: {
        "fileName": filename
      },
      onError: () => {
        Notify.failure("Failed to get file");
      },
      onCompleted: (data) => {
        setCurrTopicFile(data?.getFileURL);
      }
    });
  }

  const handleTopicChange = (topic) => {
    setCurrTopic(topic);
    if (topic.contentType && topic.contentType === "file") {
      handleGetFileURL(topic?.file);
    } else {
      setCurrTopicFile(null)
    }
  };

  const handleQuizComplete = (quizGUID, data) => {
    if (!isReview) {
      const payload = {
        "quizQuestionId": quizGUID,
        "userId": user?.guid,
        "isInstructorAnswer": false,
        "answerData": data
      };

      onCreateQuizAnswer({
        variables: payload,
        onCompleted: () => Notify.success('Quiz saved successfully!'),
        onError: () => Notify.failure('Failed to save quiz!'),
      })
    }
  }

  return (
    <Fragment>
      <NavbarDefault login />
      <main>
        <section className="mt-6 course-container">
          <Container fluid>
            <Row>
              <Col sm={12} md={12} lg={12}>
                {/*  Tab content  */}
                <div className="content">
                  {/*  Video */}
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h3 className=" mb-0 pe-6">
                        {module?.title}
                      </h3>
                    </div>
                    <div className="d-flex justify-content-between">
                      <Button
                        className="me-2"
                        variant="outline-primary"
                        size="sm"
                        disabled={getPrevModule(allModules, guid) === null}
                        onClick={handlePreviousLecture}
                      >
                        Previous Lecture
                      </Button>
                      <Button
                        size="sm"
                        disabled={getNextModule(allModules, guid) === null}
                        onClick={handleNextLecture}
                      >
                        Next Lecture
                      </Button>
                      {/* <Dropdown className="video-info-icon me-2">
                        <Dropdown.Toggle
                          bsPrefix=" "
                          as="a"
                          href="#"
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          <i className="fe fe-help-circle text-secondary"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="p-3"
                          style={{ width: "300px" }}
                        >
                          <span></span>
                        </Dropdown.Menu>
                      </Dropdown>
                      <ActionMenu /> */}
                    </div>
                  </div>
                  <div
                    className="embed-responsive position-relative w-100 d-block overflow-hidden p-0"
                    style={{ height: "600px" }}
                  >
                    {currTopic?.contentType &&
                      (currTopic?.contentType === "file" ||
                        currTopic?.contentType === "image" ? (
                        // <PDFViewerComponent
                        //   document={currTopicFile || currTopic?.image}
                        // />
                        // <DocumentViewer docs={[{uri: currTopicFile, fileType: 'pptx'}]}/>
                        <>
                          {currTopic !== null &&
                            <PdfViewer doc={currTopicFile} />
                          }
                        </>
                      ) : (
                        <VideoPlayer
                          title={currTopic?.title}
                          video={currTopic?.video || ""}
                        />
                      ))}

                    {currTopic?.questionData && (
                      <div className="h-100" >
                        <SurveyViewer
                          questions={
                            currTopic?.questionData
                          }
                          onComplete={(data) => handleQuizComplete(currTopic.guid, data)}
                        />
                        {/* {currTopic?.quizquestionSet.length} */}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*  Card */}
        <section className="card course-sidebar " id="courseAccordion">
          <SimpleBar style={{ maxHeight: "93vh" }}>
            <Card>
              <Card.Header>
                <h4 className="mb-0">Table of Contents</h4>
              </Card.Header>
              {/* Course Index Accordion */}
              <GKAccordionModule
                accordionItems={contents}
                onSelected={handleTopicChange}
                currTopic={currTopic}

              />
            </Card>
          </SimpleBar>
        </section>
      </main>
    </Fragment>
  );
};

export default CourseResume;
