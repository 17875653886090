import { gql } from "@apollo/client";

export const GET_COURSE_MODULES = gql`
  query AllCourseModules($courseGuid: UUID) {
    allCourseModules(course_Guid: $courseGuid) {
      edges {
        node {
          guid
          id
          title
          coursemodulecontentsSet {
            edges {
              node {
                description
                guid
                id
                title
              }
            }
          }
          modulecontentquizSet {
            edges {
              node {
                guid
                questionData
                startDateTime
                endDateTime
                title
                questionData
              }
            }
          }
          coursemoduleresourcesSet {
            edges {
              node {
                guid
                video
                name
                image
                id
                file
                contentType
              }
            }
          }
          description
          mustComplete
        }
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query AllCourses($guid: UUID, $userGuid: UUID) {
    allCourses(guid: $guid) {
      edges {
        node {
         virtualLink
         tags
          introImage
          introVideo
          courseStartDate
          courseEndDate
          learnObjectives
          creator {
            firstName
            guid
            id
            lastName
            email
          }
          createdAt
          dificultyLevel
          description
          guid
          id
          isActive
          language
          package {
            guid
            createdAt
            price
            name
            isFree
          }
          price
          requirements
          status
          title
          coursereviewsSet {
            edges {
              node {
                description
                rating
                user {
                  firstName
                  guid
                  email
                  lastName
                }
                id
              }
            }
          }
          coursemodulesSet {
            edges {
              node {
                coursemodulecontentsSet {
                  edges {
                    node {
                      guid
                      description
                      title
                      module {
                        title
                        guid
                        description
                      }
                    }
                  }
                }
                title
                guid
                description
              }
            }
          }
          usercourseprogressSet(user_Guid: $userGuid) {
            edges {
              node {
                courseProgress
                guid
              }
            }
          }
             courseChatGroup {
          guid
          name
          id
        }
        }
      }
    }
  }
`;

export const GET_USER_PAID_PACKAGES = gql`
  query AllPackagePayments($userIdGuid: UUID, $package: ID) {
    allPackagePayments(userId_Guid: $userIdGuid, package: $package) {
      edges {
        node {
          guid
          id
        }
      }
    }
  }
`;

export const GET_USER_PAID_COURSES = gql`
  query AllPackagePayments($userIdGuid: UUID, $course: ID) {
  allPackagePayments(userId_Guid: $userIdGuid, course: $course) {
    edges {
      node {
        guid
        id
        course {
          guid
        }
      }
    }
  }
}
`;

export const GET_COURSES = gql`
  query AllCourses($isActive: Boolean) {
    allCourses(isActive: $isActive) {
      edges {
        node {
          introImage
          tags
          introVideo
          courseEndDate
          courseStartDate
          createdAt
          creator {
            firstName
            lastName
            email
            guid
            id
          }
          dificultyLevel
          guid
          id
          isActive
          title
          status
          price
          package {
            name
            isFree
            id
            guid
          }
          coursereviewsSet {
            edges {
              node {
                rating
                guid
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MY_COURSES = gql`
  query AllCourses {
    allCourses {
      edges {
        node {
          creator {
            guid
            firstName
            lastName
          }
          id
          guid
          title
          dificultyLevel
          language
          description
          status
          introImage
          createdAt
          courseStartDate
          courseEndDate
          tags
        }
      }
    }
  }
`;

export const GET_MODULE_BY_ID = gql`
  query AllCourseModules($guid: UUID) {
    allCourseModules(guid: $guid) {
      edges {
        node {
          coursemoduleresourcesSet {
            edges {
              node {
                contentType
                file
                guid
                id
                image
                name
                video
              }
            }
          }
          description
          title
          mustComplete
          course {
            guid
          }
          modulecontentquizSet {
            edges {
              node {
                title
                startDateTime
                endDateTime
                description
                guid
                questionData
              }
            }
          }
          coursemodulecontentsSet {
            edges {
              node {
                title
                guid
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_COURSES = gql`
  query AllCourses {
    allCourses {
      edges {
        node {
          introImage
          introVideo
          courseEndDate
          courseStartDate
          createdAt
          creator {
            firstName
            lastName
            email
            guid
            id
          }
          dificultyLevel
          guid
          id
          isActive
          title
          status
          description
          language
          price
          package {
            name
            isFree
            id
            guid
          }
        }
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
query AllRoles {
  allRoles {
    edges {
      node {
        id
        name
      }
    }
  }
}
`;

export const GET_ALL_USERS = gql`
query AllUsers {
  allUsers {
    edges {
      node {
        guid
        firstName
        lastName
        role {
          name
          id
        }
        createdAt
        email
      }
    }
  }
}
`;

export const GET_ENROLLED_COURSES = gql`
query AllPackagePayments($userIdGuid: UUID) {
  allPackagePayments(userId_Guid: $userIdGuid) {
    edges {
      node {
        course {
          guid
          title
          coursereviewsSet {
            edges {
              node {
                guid
                description
                rating
              }
            }
          }
          creator {
            firstName
            email
            lastName
            guid
            id
          }
          createdAt
          dificultyLevel
          isActive
          price
          introImage
          description
          courseEndDate
          courseStartDate
        }
      }
    }
  }
}
`;

export const GET_FILE_URL = gql`
query Query($fileName: String!) {
  getFileURL(fileName: $fileName)
}
`;

export const GET_USER_BY_ID = gql`
  query AllUsers($guid: UUID) {
  allUsers(guid: $guid) {
    edges {
      node {
        guid
        firstName
        lastName
        email
        phoneNumber
        dateOfBirth
        gender
        country
        state
      }
    }
  }
}
`;

export const GET_CHAT_INFO_BY_GUID = gql`
query AllChatGroupsMessages($chatGroupGuid: UUID) {
  allChatGroupsMessages(chatGroup_Guid: $chatGroupGuid) {
    edges {
      node {
        guid
        id
        message
        user {
          guid
          firstName
          lastName
        }
        createdAt
      }
    }
  }
}
`;

export const GET_PATIENTS = gql`
query AllUsers($isPatient: Boolean, $createdBy: String) {
  allUsers(isPatient: $isPatient, createdBy: $createdBy) {
    edges {
      node {
        firstName
        email
        guid
        lastName
        phoneNumber
        state
        dateOfBirth
        createdAt
        country
        city
      }
    }
  }
}
`;

export const GET_PATIENT_FORMS = gql`
query AllPatientForms($patientIdGuid: UUID) {
  allPatientForms(patientId_Guid: $patientIdGuid) {
    edges {
      node {
        formType
        guid
        description
        createdBy
        createdAt
        id
      }
    }
  }
}
`;

const queries = [
  GET_COURSE_MODULES,
  GET_COURSE_BY_ID,
  GET_USER_PAID_PACKAGES,
  GET_MY_COURSES,
  GET_MODULE_BY_ID,
  GET_ALL_COURSES,
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_USER_BY_ID,
];

export default queries;
