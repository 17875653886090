// import node module libraries
import React, { useState, Fragment, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Col, Row, Container } from "react-bootstrap";

// import custom components
import GKStepper from "components/elements/stepper/GKStepper";

// import sub components ( Steps )
import BasicInformation from "./steps/BasicInformation";
import CoursesMedia from "./steps/CoursesMedia";
import Curriculum from "./steps/Curriculum";
// import Settings from './steps/Settings';
import { PATH_DASHBOARD } from "routes/Paths";
import { Notify } from "notiflix";

// ----------------------------------------------------------------------
import { CREATE_NEW_COURSE } from "helper/graphql/mutations";
import { GET_COURSE_BY_ID } from "helper/graphql/queries";
import moment from "moment";

// ----------------------------------------------------------------------

const AddNewCourse = () => {
  const { guid } = useParams();

  const { pathname } = useLocation();

  const isEdit = pathname.includes("edit");

  const user = JSON.parse(localStorage.getItem("user"));
  const [onCreateCourse, { loading: isCreateCourse }] =
    useMutation(CREATE_NEW_COURSE);

  const [currentStep, setCurrentStep] = useState(1);
  const [newCourseGuid, setNewCourseGuid] = useState(null);
  const [course, setCourse] = useState();


  useQuery(GET_COURSE_BY_ID, {
    variables: { guid: guid },
    onCompleted: (data) => {
      setCourse(data?.allCourses?.edges[0]?.node);
    },
    onError: () => {
      Notify.failure("Failed to fetch course!");
    },
  });

  const [formData, setFormData] = useState({
    title: null,
    courseEndDate:  null,
    courseStartDate: null,
    description: null,
    language: null,
    dificultyLevel: null,
    requirements:  ["Stable Internet Connection"],
    status: "Draft",
    creatorId:  user?.guid,
    learnObjectives:[],
    isActive: false,
    tags: []
    // // introImage: [],
    // introVideo: [],
  });

  useEffect(() => {
    if (course && isEdit) {
      setFormData({
        title: course?.title || null,
        courseEndDate: moment(course?.courseEndDate).format('YYYY-MM-DDTHH:mm') || null,
        courseStartDate: moment(course?.courseStartDate).format('YYYY-MM-DDTHH:mm') || null,
        description: course?.description || null,
        language: course?.language || null,
        dificultyLevel: course?.dificultyLevel || null,
        requirements: course?.requirements || ["Stable Internet Connection"],
        status: course?.status || "Draft",
        creatorId: course?.creator?.id || user?.guid,
        learnObjectives: course?.learnObjectives || [],
        price: course?.price || 0,
        tags: course?.tags || 0,
        // introImage: [],
        // introVideo: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files,
      }));
    } else {
      if(name === 'price'){
        setFormData((prevState) => ({
          ...prevState,
          [name]: parseInt(value),
        }));
      } else if(name === 'tags'){
        setFormData((prevState) => ({
          ...prevState,
          [name]: [value],
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleAddDataToCourse = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveCourse = () => {
    onCreateCourse({
      variables: formData,
      onCompleted: (data) => {
        Notify.success("Course created successfully!");
        next();
        setNewCourseGuid(data?.createCourse);
      },
      onError: () => {
        Notify.failure("Failed to create course!");
      },
    });
    // next();
  };

  const next = () => {
    setCurrentStep(currentStep === 4 ? 1 : currentStep + 1);
  };
  const previous = () => {
    setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
  };

  const steps = [
    {
      id: 1,
      title: "Basic Information",
      content: (
        <BasicInformation
          data={formData}
          handleChange={handleChange}
          next={next}
        />
      ),
    },
    {
      id: 2,
      title: "Courses Media",
      content: (
        <CoursesMedia
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
          createCourse={handleSaveCourse}
          addCourseInfo={handleAddDataToCourse}
          isLoading={isCreateCourse}
        />
      ),
    },
    {
      id: 3,
      title: "Curriculum",
      content: (
        <Curriculum
          course={newCourseGuid || course?.guid}
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
        />
      ),
    },
    // {
    // 	id: 4,
    // 	title: 'Settings',
    // 	content: (
    // 		<Settings
    // 			data={formData}
    // 			handleChange={handleChange}
    // 			next={next}
    // 			previous={previous}
    // 		/>
    // 	)
    // }
  ];
  const editSteps = [
    
    {
      id: 1,
      title: "Curriculum",
      content: (
        <Curriculum
          course={newCourseGuid || course?.guid}
          data={formData}
          handleChange={handleChange}
          next={next}
          previous={previous}
        />
      ),
    },
    // {
    // 	id: 4,
    // 	title: 'Settings',
    // 	content: (
    // 		<Settings
    // 			data={formData}
    // 			handleChange={handleChange}
    // 			next={next}
    // 			previous={previous}
    // 		/>
    // 	)
    // }
  ];

  return (
    <Fragment>
      <section className="py-4 py-lg-6 bg-primary">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1">{`${isEdit ? "Edit" : "Add New"} Course`}</h1>
                  <p className="mb-0 text-white lead">
                    Just fill the form and create your courses.
                  </p>
                </div>
                <div>
                  <Link
                    to={PATH_DASHBOARD.instructor.courses}
                    className="btn btn-white "
                  >
                    Back to Course
                  </Link>{" "}
                  <Link
                    to={PATH_DASHBOARD.instructor.courses}
                    className="btn btn-dark "
                  >
                    Save
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <GKStepper currentStep={currentStep} steps={isEdit ? editSteps : steps} />
    </Fragment>
  );
};

export default AddNewCourse;
